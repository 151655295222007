<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>
      <b-card>
        <!-- <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img rounded height="80" />
            </b-link>
          </b-media-aside>
          <b-media-body class="mt-75 ml-75">
            <b-button variant="primary" size="sm" class="mb-75 mr-75">
              Upload
            </b-button>
            <b-form-file accept=".jpg, .png, .gif" :hidden="true" plain />
            <b-card-text
              >Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text
            >
          </b-media-body>
        </b-media> -->
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col sm="6">
              <b-form-group label="Username" label-for="account-username">
                <b-form-input v-model="general.Username" placeholder="Username" name="Username" />
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group label="Name" label-for="account-name">
                <b-form-input v-model="general.Name" name="Name" placeholder="Name" />
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group label="E-mail" label-for="account-e-mail">
                <b-form-input v-model="general.Email" name="Email" placeholder="Email" />
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group label="Phone" label-for="account-phone">
                <b-form-input v-model="general.Phone" name="Phone" placeholder="Mobile Phone" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button @click="generalSubmit" variant="primary" class="mt-2 mr-1">
                Save changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-tab>
    <!--/ general tab -->
    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Change Password</span>
      </template>
      <b-card>
        <b-form>
          <b-row>
            <!-- old password -->
            <b-col md="12">
              <b-form-group
                label="Old Password"
                label-for="account-old-password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="pass.Password"
                    name="Password"
                    placeholder="Old Password"
                    type="password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!--/ old password -->
          </b-row>
          <b-row>
            <!-- new password -->
            <b-col md="6">
              <b-form-group
                label-for="account-new-password"
                label="New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="pass.NewPassword"
                    name="NewPassword"
                    placeholder="New Password"
                    type="password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!--/ new password -->
            <!-- retype password -->
            <b-col md="6">
              <b-form-group
                label-for="account-retype-new-password"
                label="Retype New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="pass.ReNewPassword"
                    name="ReNewPassword"
                    placeholder="New Password"
                    type="password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!--/ retype password -->
            <!-- buttons -->
            <b-col cols="12">
              <b-button
                variant="primary"
                class="mt-1 mr-1"
                @click="passSubmit"
              >
                Save changes
              </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </b-card>
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
/* eslint-disable */
import {
  BTabs,
  BTab,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ToastificationContent
  },
  data() {
    return {
      general: {
        Username: '',
        Name: '',
        Email: '',
        Phone: '',
      },
      pass: {
        Password: '',
        NewPassword: '',
        ReNewPassword: '',
      }
    };
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$Progress.start()
      this.$http.get('/authorization/users')
        .then(res => {
          this.general.Username = res.data.Username
          this.general.Name = res.data.Name
          this.general.Email = res.data.Email
          this.general.Phone = res.data.Phone
          this.$Progress.finish()
        })
    },
    generalSubmit() {
      this.$Progress.start()
      this.$http.post('/authorization/users/update/general', this.general)
      .then(res => {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Profile Updated!',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })
      })
      .catch(error => {
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
            })
      })
      this.$Progress.finish()
    },
    passSubmit() {
      this.$http.post('/authorization/users/update/password', this.pass)
      .then(res => {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Profile Updated!',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })
      })
      .catch(error => {
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
            })
      })
    },
  },
};
/* eslint-enable */
</script>
